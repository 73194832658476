import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import HeroSection from "../components/hero";
import Layout from "../components/layout";
import SectionLayout2 from "../components/section-layout-2";
import SectionLayout3 from "../components/section-layout-3";
import SectionLayout4 from "../components/section-layout-4";
import SectionLayout5 from "../components/section-layout-5";
import SectionLayout6 from "../components/section-layout-6";
import SectionLayout7 from "../components/section-layout-7";
import SectionLayout8 from "../components/section-layout-8";
import SectionLayout9 from "../components/section-layout-9";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}

			post: wpBniLandingPage(title: { eq: "London" }) {
				title
				slug
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						altText
						publicUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
				pageFields {
					meetingDetails {
						dayOfWeek
						meetingType
						timeOfMeeting
					}
					pageSections {
						... on WpBNILandingPage_Pagefields_PageSections_HeroSection {
							body
							fieldGroupName
							formTitle
							heading

							meetingNote
							meetingText
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout2 {
							fieldGroupName
							heading
							items {
								body
								title
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout3 {
							body
							fieldGroupName
							heading
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout4 {
							body
							fieldGroupName
							heading
							button {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout5 {
							fieldGroupName
							heading
							meetingDetailHeading

							meetingDetailNote
							memberCount
							memberCountText
							link {
								target
								title
								url
							}
							membersLink {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout6 {
							body
							fieldGroupName
							heading
							reviews {
								... on WpTestimonial {
									id
									testimonialsPostFields {
										review
										authorName
										authorJobRole
										authorImage {
											altText
											sourceUrl
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
										authorCompanyLogo {
											altText
											sourceUrl
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout7 {
							body
							fieldGroupName
							heading
							items {
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout8 {
							fieldGroupName
							heading
							leadershipMember {
								... on WpLeadership {
									id
									leadershipPostField {
										name
										linkedinUrl
										companyName
										image {
											altText
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
										role
										twitterUrl
										webUrl
									}
								}
							}
						}
						... on WpBNILandingPage_Pagefields_PageSections_SectionLayout9 {
							body
							fieldGroupName
							formTitle
							heading
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// if (typeof window !== "undefined") {
	// 	window.location = "/st-pauls";
	// }
	const post = data.post;

	const isRestrictedDate = (date) => {
		const month = date.getMonth(); // 0 - January, 11 - December
		const day = date.getDate();

		// Exclude dates from December 23 to December 31
		if (month === 11 && day >= 23) {
			return true;
		}

		// Exclude dates from January 1 to January 5
		if (month === 0 && day <= 5) {
			return true;
		}

		return false;
	};

	const getNextMeetingDate = (dayOfWeek) => {
		const today = new Date();
		const targetDayIndex = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		].indexOf(dayOfWeek);

		let nextMeeting = new Date(today);

		// Align to the next occurrence of the target day
		const daysToAdd = (targetDayIndex - nextMeeting.getDay() + 7) % 7 || 7;
		nextMeeting.setDate(nextMeeting.getDate() + daysToAdd);

		// Skip restricted dates
		while (isRestrictedDate(nextMeeting)) {
			nextMeeting.setDate(nextMeeting.getDate() + 7); // Move to the next week
		}

		return nextMeeting;
	};

	const isFirstMeetingOfMonth = (date) => {
		const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
		const firstMeeting = new Date(firstDayOfMonth);
		firstMeeting.setDate(
			firstDayOfMonth.getDate() +
				((7 - firstDayOfMonth.getDay() + date.getDay()) % 7)
		);
		return date.toDateString() === firstMeeting.toDateString();
	};

	const getMeetingType = (meetingType, date) => {
		if (meetingType === "Hybrid") {
			return isFirstMeetingOfMonth(date) ? "In-person" : "Zoom";
		}
		return meetingType; // Return 'In-person' or 'Zoom' directly
	};

	const hasMeetingStartedOrEnded = (date, time) => {
		const now = new Date();
		const meetingStart = new Date(date);
		const [hours, minutes, seconds] = time.split(":").map(Number);
		meetingStart.setHours(hours, minutes, seconds, 0);

		return now >= meetingStart;
	};

	const formatMeetingString = (date, type) => {
		const day = date.getDate();
		const suffix = ["th", "st", "nd", "rd"];
		const v = day % 100;
		const dayWithSuffix =
			day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);

		const month = date.toLocaleDateString("en-US", { month: "long" });
		const year = date.getFullYear();
		const typeString = type === "Zoom" ? `via ${type}` : type;
		return `${dayWithSuffix} ${month} ${year} (${typeString})`;
	};

	const formatMeetingStringOther = (date, type) => {
		const day = date.getDate();
		const suffix = ["th", "st", "nd", "rd"];
		const v = day % 100;
		const dayWithSuffix =
			day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);

		const month = date.toLocaleDateString("en-US", { month: "long" });
		const year = date.getFullYear();
		const typeString = type === "Zoom" ? `via ${type}` : type;
		return `${dayWithSuffix} ${month}`;
	};

	const meetingData = post.pageFields.meetingDetails;
	const dayOfWeek = meetingData.dayOfWeek;
	const timeOfMeeting = meetingData.timeOfMeeting;
	const meetingType = meetingData.meetingType;

	const [nextMeetingDate, setNextMeetingDate] = useState(
		getNextMeetingDate(dayOfWeek)
	);
	const [calculatedMeetingType, setCalculatedMeetingType] = useState(
		getMeetingType(meetingType, nextMeetingDate)
	);
	const [nextFourMeetingStrings, setNextFourMeetingStrings] = useState([]);

	useEffect(() => {
		const updateMeetingData = () => {
			const today = new Date();
			const currentDay = today.getDay();
			const targetDay = [
				"Sunday",
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			].indexOf(dayOfWeek);

			let newDate = getNextMeetingDate(dayOfWeek);
			const newMeetingType = getMeetingType(meetingType, newDate);

			if (
				currentDay === targetDay &&
				hasMeetingStartedOrEnded(today, timeOfMeeting)
			) {
				newDate = new Date(newDate.setDate(newDate.getDate() + 7)); // Advance to the next week
				setNextMeetingDate(newDate);
				setCalculatedMeetingType(getMeetingType(meetingType, newDate));
			} else {
				setNextMeetingDate(newDate);
				setCalculatedMeetingType(newMeetingType);
			}

			const upcomingMeetings = getNextFourMeetingDates(
				dayOfWeek,
				meetingType,
				newDate
			);
			setNextFourMeetingStrings(
				upcomingMeetings.map((meeting) =>
					formatMeetingString(meeting.date, meeting.type)
				)
			);
		};

		const getNextFourMeetingDates = (dayOfWeek, meetingType, startDate) => {
			const dates = [];
			let nextDate = new Date(startDate);

			while (dates.length < 4) {
				// Align to the correct weekday
				const targetDay = [
					"Sunday",
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday",
				].indexOf(dayOfWeek);

				if (nextDate.getDay() !== targetDay) {
					const daysToAdd = (targetDay - nextDate.getDay() + 7) % 7;
					nextDate.setDate(nextDate.getDate() + daysToAdd);
				}

				// Skip restricted dates
				if (isRestrictedDate(nextDate)) {
					nextDate.setDate(nextDate.getDate() + 7);
					continue; // Recheck the new date
				}

				// Determine meeting type
				let type = getMeetingType(meetingType, nextDate);

				// For hybrid meetings, only the first of the month is "In-person"
				if (meetingType === "Hybrid" && !isFirstMeetingOfMonth(nextDate)) {
					type = "Zoom";
				}

				// Add the valid date
				dates.push({
					date: new Date(nextDate), // Copy the valid date
					type: type,
				});

				// Move to the next week
				nextDate.setDate(nextDate.getDate() + 7);
			}

			return dates;
		};

		updateMeetingData(); // Run immediately on component mount
		const interval = setInterval(updateMeetingData, 1000 * 60); // Check every minute

		return () => clearInterval(interval);
	}, [dayOfWeek, meetingType, timeOfMeeting]);

	const formatTime = (time) => {
		const [hours, minutes] = time.split(":").map(Number);
		const period = hours >= 12 ? "pm" : "am";
		const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
		return `${adjustedHours}:${minutes.toString().padStart(2, "0")}${period}`;
	};

	const meetingDateString = formatMeetingStringOther(
		nextMeetingDate,
		calculatedMeetingType
	);
	const meetingDayString = nextMeetingDate.toLocaleDateString("en-US", {
		weekday: "long",
	});

	const formattedTimeOfMeeting = formatTime(timeOfMeeting);

	const formatMeetingText = (text) => {
		if (typeof text !== "string") {
			return text; // Return as-is if it's not a string
		}

		// Use a regular expression to split the string where we find numbers with suffixes (th, rd, st, nd)
		const parts = text.split(/(\d+)(th|rd|st|nd)/g);

		return parts.map((part, index) => {
			// Check if this is a number
			if (/^\d+$/.test(part)) {
				return <span key={index}>{part}</span>; // The number part
			}
			// Check if this is an ordinal suffix we need to superscript
			else if (/^(th|rd|st|nd)$/.test(part)) {
				return <sup key={index}>{part}</sup>; // The suffix part to be superscripted
			}
			// Return other parts of the string as normal text
			else {
				return <span key={index}>{part}</span>;
			}
		});
	};

	const meetingSentence1 = (
		<>
			<span className="arimo-bold">{formatMeetingText(meetingDateString)}</span>{" "}
			at {formattedTimeOfMeeting} -{" "}
			{calculatedMeetingType === "Zoom" ? "via Zoom" : calculatedMeetingType}
		</>
	);

	const meetingSentence2 = (
		<>
			{meetingDayString}{" "}
			<span className="arimo-bold">{formatMeetingText(meetingDateString)}</span>{" "}
			at {formattedTimeOfMeeting}
		</>
	);

	const meetingSentence3 = `${meetingDayString} ${formattedTimeOfMeeting}`;

	const {
		seoFieldGroups,
		pageFields: { pageSections },
	} = post;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<GatsbySeo title={seoFieldGroups?.metaTitle} language="en" noindex />

			<div>
				<Layout meeting={meetingSentence1} subTitle={post?.title}>
					{pageSections.length > 0 &&
						pageSections.map((sectionInfo) => (
							<>
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_HeroSection" && (
									<HeroSection
										meetingLocation={post.title}
										dropdown={nextFourMeetingStrings}
										heading={sectionInfo?.heading}
										content={sectionInfo?.body}
										meetingText={sectionInfo?.meetingText}
										meetingInfo={meetingSentence2}
										meetingNote={sectionInfo?.meetingNote}
										formTitle={sectionInfo?.formTitle}
										bgImage={
											sectionInfo.backgroundImage.localFile.childImageSharp
												.gatsbyImageData
										}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout2" && (
									<SectionLayout2
										heading={sectionInfo?.heading}
										items={sectionInfo?.items}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout3" && (
									<SectionLayout3
										heading={sectionInfo?.heading}
										content={sectionInfo?.body}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout4" && (
									<SectionLayout4
										heading={sectionInfo?.heading}
										content={sectionInfo?.body}
										button={sectionInfo?.button}
										bgImage={
											sectionInfo.backgroundImage.localFile.childImageSharp
												.gatsbyImageData
										}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout5" && (
									<SectionLayout5
										heading={sectionInfo?.heading}
										memberCount={sectionInfo?.memberCount}
										memberCountText={sectionInfo?.memberCountText}
										membersLink={sectionInfo?.membersLink}
										meetingDetailHeading={sectionInfo?.meetingDetailHeading}
										meetingDetail1={meetingSentence3}
										meetingDetail2={meetingType}
										meetingDetailNote={sectionInfo?.meetingDetailNote}
										bgImage={
											sectionInfo.backgroundImage.localFile.childImageSharp
												.gatsbyImageData
										}
										button={sectionInfo?.link}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout6" && (
									<SectionLayout6
										heading={sectionInfo?.heading}
										content={sectionInfo?.body}
										testimonials={sectionInfo?.reviews}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout7" && (
									<SectionLayout7
										heading={sectionInfo?.heading}
										content={sectionInfo?.body}
										items={sectionInfo?.items}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout8" && (
									<SectionLayout8
										heading={sectionInfo?.heading}
										items={sectionInfo?.leadershipMember}
									/>
								)}
								{sectionInfo.fieldGroupName ===
									"BNILandingPage_Pagefields_PageSections_SectionLayout9" && (
									<SectionLayout9
										meetingLocation={post.title}
										dropdown={nextFourMeetingStrings}
										heading={sectionInfo?.heading}
										content={sectionInfo?.body}
										formTitle={sectionInfo?.formTitle}
									/>
								)}
							</>
						))}
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
